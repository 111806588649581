<template>
  <div>
    <div :style="{display: 'flex', justifyContent: 'center', width: '100%'}">
    <div :style="{ padding: '30px 0', width:'50vw'}">
      <h1>비밀번호 초기화</h1>
    </div>
  </div>

  <p v-if="!checkEmail">비밀번호 초기화 하고싶은 이메일을 입력해주세요.</p>
  <p v-else-if="!checkCode">이메일로 받은 인증코드를 입력해주세요.</p>
  <p v-else>변경하고 싶은 비밀번호를 입력해주세요.</p>

  <div v-if="!checkCode">
    <div class="mt-4">
      <div class="form-group">
        <label for="exampleInputEmail1">Email</label>
        <input type="email" v-model="email" class="form-control mb-0" id="exampleInputEmail" placeholder="Enter email" :readonly="this.checkEmail">
      </div>

      <div v-if="checkEmail" class="form-group">
        <label for="exampleInputEmail1">인증번호</label>
        <input type="email" v-model="code"
          @input="code=$event.target.value.toUpperCase()"
          class="form-control mb-0" placeholder="Enter Authentication code">
      </div>
    </div>
  </div>
  <div v-else>
    <div class="mt-4">
      <div class="form-group">
        <label for="exampleInputEmail1">Passwrod</label>
        <input type="password" v-model="password" class="form-control mb-0">
        <label class="mt-4" for="exampleInputEmail1">Check Passwrod</label>
        <input type="password" v-model="checkPassword" class="form-control mb-0">
      </div>
  </div>
</div>
  <div class="d-inline-block w-100">
        <button v-if="!checkEmail" @click="getMail()" class="btn btn-primary float-right">Email 인증</button>
        <button v-else-if="!checkCode" @click="checkPassCode()" class="btn btn-primary float-right">코드 확인</button>
        <button v-else @click="changePassword()" class="btn btn-primary float-right">비밀번호 초기화</button>
      </div>
      </div>
</template>
<script>
import { core } from '../../config/pluginInit'
import auth from '../../services/auth'

export default {
  name: 'RecoverPassword',
  data () {
    return {
      email: '',
      checkEmail: false,
      code: '',
      checkCode: false,
      token: '',
      password: '',
      checkPassword: ''
    }
  },
  mounted () {
    core.index()
  },
  methods: {
    async getMail () {
      if (this.email === '') {
        alert('이메일을 입력해주세요.')
      }
      const res = await auth.checkPassEmail(this.email)

      if (!res.resultCode) {
        if (res.message === 'not exist') {
          alert('가입되지 않은 이메일입니다.')
          return
        }

        if (res.message === 'block') {
          alert('활동이 제한된 이메일입니다.')
          return
        }
      }

      this.checkEmail = true
    },

    async checkPassCode () {
      if (this.code === '') {
        alert('인증코드를 입력해주세요.')
        return
      }
      const res = await auth.checkPassCode(this.email, this.code)

      if (res.message === 'not match') {
        alert('정확한 인증번호를 입력해주세요.')
        return
      }
      this.token = res['password-token']
      this.checkCode = true
    },

    async changePassword () {
      if (this.password === '') {
        alert('비밀번호를 입력해주세요.')
        return
      }
      if (this.checkPassword === '') {
        alert('비밀번호를 입력해주세요.')
        return
      }
      if (this.password !== this.checkPassword) {
        alert('동일한 비밀번호를 입력해주세요.')
        return
      }
      const res = await auth.changePassword(this.token, this.password)

      if (!res.resultCode) {
        alert('관리자에게 문의해주세요.')
        return
      }
      alert('비밀번호 초기화가 완료되었습니다.\n다시 로그인해주세요.')
      this.$router.push({ name: 'auth.sign-in' })
    }
  }
}
</script>
